<template>
  <div class="vacancy-listing-modal">
    <div class="modal-header">
      <div class="title">{{ taxonomyData.vacancy.title }}</div>
      <div class="vacancyId">{{ taxonomyData.vacancy.vacancyId }}</div>
    </div>
    <div class="taxonomy-section">
      <div class="heading">
        <div class="minus-icon">
          <MinusOutlined class="svgicon" />
        </div>
        <div class="taxonomy-title">
          <h3 style="margin-bottom: 0">Taxonomy</h3>
        </div>
      </div>
      <div class="taxonomy-content-block">
        <div class="content">
          <div class="key-data">Industry :</div>

          <a-tag
            class="value-data"
            v-for="industry in taxonomyData.taxonomy.industries"
            :key="industry.id"
          >
            {{ industry.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Sector :</div>

          <a-tag
            class="value-data"
            v-for="sector in taxonomyData.taxonomy.sectors"
            :key="sector.id"
          >
            {{ sector.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Practice Area :</div>
          <a-tag
            class="value-data"
            v-for="practiceArea in taxonomyData.taxonomy.practiceAreas"
            :key="practiceArea.id"
          >
            {{ practiceArea.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Sub Practice Area :</div>

          <a-tag
            class="value-data"
            v-for="subPracticeArea in taxonomyData.taxonomy.subPracticeAreas"
            :key="subPracticeArea.id"
          >
            {{ subPracticeArea.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Functions :</div>

          <a-tag
            class="value-data"
            v-for="item in taxonomyData.taxonomy.functions"
            :key="item.id"
          >
            {{ item.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">SF Work Area :</div>

          <a-tag
            class="value-data"
            v-for="subFunction in taxonomyData.taxonomy.sfWorkAreas"
            :key="subFunction.id"
          >
            {{ subFunction.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">SSF Tag :</div>

          <a-tag
            class="value-data"
            v-for="ssf in taxonomyData.taxonomy.ssfTags"
            :key="ssf.id"
          >
            {{ ssf.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Product :</div>

          <a-tag
            class="value-data"
            v-for="product in taxonomyData.taxonomy.products"
            :key="product.id"
          >
            {{ product.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Region :</div>

          <a-tag
            class="value-data"
            v-for="region in taxonomyData.taxonomy.regions"
            :key="region.id"
          >
            {{ region.name }}
          </a-tag>
        </div>
        <div class="content">
          <div class="key-data">Segment :</div>

          <a-tag
            class="value-data"
            v-for="segment in taxonomyData.taxonomy.segments"
            :key="segment.id"
          >
            {{ segment.name }}
          </a-tag>
        </div>
      </div>
    </div>
    <a-divider />

    <div class="assigned-to-widget-section">
      <div class="heading">
        <div class="minus-icon">
          <MinusOutlined class="svgicon" />
        </div>
        <div class="taxonomy-title">
          <h3 style="margin-bottom: 0">Assigned To</h3>
        </div>
      </div>
      <div style="margin-left: 3rem; margin-right: 1rem">
        <div class="assigned-to-rm-tagged">
          <div class="rm-tagged-text">RM Tagged</div>
          <div class="rm-tagged-response"></div>
        </div>

        <div class="rm-tagged-indicators-wrapper">
          <div style="display: flex; align-items: center">
            <div class="rm-tagged-legend-circle red-circle"></div>
            <div class="rm-tagged-legend-texts texts-red">No RM</div>
          </div>
          <div style="display: flex; align-items: center">
            <div class="rm-tagged-legend-circle yellow-circle"></div>
            <div class="rm-tagged-legend-texts texts-yellow">
              RM not within Team
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <div class="rm-tagged-legend-circle green-circle"></div>
            <div class="rm-tagged-legend-texts texts-green">RM from Team</div>
          </div>
        </div>

        <div class="team-with-rag-status-wrapper">
          <div class="team-with-rag-text">Team with RAG Status</div>
          <div>
            <a-tag
              class="tag-text"
              v-for="team in taxonomyData.teams"
              :key="team.id"
            >
              {{ team.name }}
            </a-tag>
          </div>
        </div>
        <div class="team-with-rag-status-wrapper">
          <div class="team-with-rag-text">Sub Team with RAG Status</div>
          <div>
            <a-tag
              class="tag-text"
              v-for="subTeam in taxonomyData.subTeams"
              :key="subTeam.id"
            >
              {{ subTeam.name }}
            </a-tag>
          </div>
        </div>

        <div class="my-team-wrapper">
          <div class="my-team-title">My Team</div>
          <div class="my-team-response"></div>
        </div>

        <div class="my-team-wrapper">
          <div class="my-team-title">Lead CIC</div>
          <div class="my-team-response">{{ taxonomyData.leadCic.name }}</div>
        </div>
        <div class="my-team-wrapper">
          <div class="my-team-title">Originated By</div>
          <div class="my-team-response">{{ taxonomyData.originatedBy }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MinusOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive, onMounted } from "vue";
import Services from "../../../services/apis";

export default defineComponent({
  props: ["id"],

  setup(props) {
    let taxonomyData = reactive({
      vacancy: {
        id: "",
        title: "",
        vacancyId: "",
      },
      taxonomy: {
        industries: [
          {
            id: "",
            name: "",
          },
        ],
        practiceAreas: [
          {
            id: "",
            name: "",
          },
        ],
        subPracticeAreas: [
          {
            id: "",
            name: "",
          },
        ],
        functions: [
          {
            id: "",
            name: "",
          },
        ],
        sfWorkAreas: [
          {
            id: "",
            name: "",
          },
        ],
        ssfTags: [
          {
            id: "",
            name: "",
          },
        ],
        products: [
          {
            id: "",
            name: "",
          },
        ],
        segments: [
          {
            id: "",
            name: "",
          },
        ],
        verticals: [
          {
            id: "",
            name: "",
          },
        ],
        levels: [
          {
            id: "",
            name: "",
          },
        ],
      },
      teams: [
        {
          id: "",
          name: "",
          revShare: "",
        },
      ],
      subTeams: [
        {
          id: "",
          name: "",
          revShare: "",
        },
      ],
      leadCic: {
        id: "",
        name: "",
        isLead: true,
        revShare: "",
      },
      originatedBy: "",
    });

    const fetchTaxonomyData = (props) => {
      Services.vacancyTaxonomy(props.id)
        .then((response) => {
          if (response.status === 200) {
            Object.assign(taxonomyData, response.data);
          }
        })
        .catch((e) => {
          if (e && e.response) {
            console.log("error :>> ", e);
          }
        });
    };

    onMounted(() => {
      fetchTaxonomyData(props);
    });

    return {
      taxonomyData,
      fetchTaxonomyData,
    };
  },

  components: {
    MinusOutlined,
  },
});
</script>
<style lang="scss">
@import "../styles/widget.scss";
</style>
